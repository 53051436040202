import React from 'react';
import PropTypes, { string } from 'prop-types';
import './title.css';
const Title = props => {
    const {title, className='vertical'} = props
    return (
        <h1 className={`title ${className}`}>
            <span>
                {title}
            </span>
            <span className={`border ${className}`}></span>
        </h1>
    );
};

Title.propTypes = {
    title: string,
    className: string
};

export default Title;