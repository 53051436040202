import React, { useEffect, useState } from 'react'
import { Link, NavLink, useLocation, useHistory } from 'react-router-dom'
import { links } from '../../Arrays'
import { Logo } from '../../Components/Logo/Logo'
import { Drawer } from './Drawer'
import './Navbar.css'
export const Navbar = () => {
  const [scrolled, setScrolled] = useState(false) 
  const location = useLocation()
  const history = useHistory()
  console.log(location.hash)
  const [visible, setVisible] = useState(false)
  function handleScroll(){
    if(window.scrollY > 50) {
      setScrolled(true)
    }
    else {
      setScrolled(false)
    }
  }

  const linksrow = links.map(link=> {
    return (
      <a exact onClick={()=> setVisible(false)} href={link.anchor} className={location.hash === link.anchor && 'activenavlink'}>{link.text}</a>
    )
  })
  useEffect(()=>{
      window.addEventListener('scroll', handleScroll)
  },[history])

  return (
    <>
    <div className={`navbar header ${scrolled ? `scrollednav`: ''}`}>
      <Logo img={`https://mcusercontent.com/013dcbdd2fe47e8cd211361cd/images/${!scrolled ? '5871654b-35a6-6cd2-abca-123ad5db13f8' : 'eea81702-84b3-ca0e-13de-69763f3fb2a0'}.png`}/>
      <div className="navlinksrow flexrow">
        {linksrow}
      </div>
      <div className={`${visible? 'menux' :'' } menubars`} onClick={()=> setVisible(!visible)}>
        <div className="bar"></div>
        <div className="bar bar2"></div>
        <div className="bar"></div>
      </div>
    </div>
    <Drawer setVisible={setVisible} navlinksrow={linksrow} visible={visible}/>
    <i className={`scrolltotop fa fa-chevron-up ${scrolled? 'visiblebtn':''}`} onClick={()=> window.scrollTo({top: 0})}></i>
    </>
  )
}