import "./styles.css";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch
} from "react-router-dom";
import ContextAppProvider from "./ContextAPI";
import React, { useState } from "react";
import Body from "./Website/Body/Body";
import Notifisystem from "./Notifications/Notifisystem";
import { ScrollToTop } from "./ScrollToTop";

export default function App() {
  return (
    <Router>
      <ContextAppProvider>
        <Notifisystem />
        <ScrollToTop />
        <Switch>
          <Route>
            <Body />
          </Route>
        </Switch>
      </ContextAppProvider>
    </Router>
  );
}
 