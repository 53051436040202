import React, { useState, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { links } from "../../Arrays";
import "./Footer.css";

const Footer = () => {
  const Footertitle = ({ title }) => {
    return (
      <div className="footertitle">
        <strong>{title}</strong>
        <div className="bbg">
          <div className="bbr"></div>
        </div>
      </div>
    );
  };
  
  const linksrow = links.map((link) => {
    return (
      <Link to={link.href}>
        <span>{link.text}</span>
      </Link>
    );
  });
  const sociallinks = [
    {
      icon: "fab fa-facebook-f",
      link: "https://www.facebook.com/cciff.ca/"
    },
    {
      icon: "fab fa-twitter",
      link: "https://twitter.com/cciff_ca"
    },
    {
      icon: "fab fa-instagram",
      link: "https://www.instagram.com/cciff_ca/"
    },
    {
      icon: "fab fa-weibo",
      link: "https://www.weibo.com/5878518557/profile?topnav=1&wvr=6&is_all=1"
    },
    {
      icon: "fab fa-youtube",
      link: "https://www.youtube.com/channel/UCcJ-jSP6Cya8o-aWoB4l3Pw"
    },
    {
      icon: "fab fa-linkedin",
      link: "https://www.linkedin.com/company/cciff/"
    },
    {
      icon: "fab fa-flickr",
      link: "https://www.flickr.com/photos/148397603@N02/"
    }
  ];
  const sociallinksrow = sociallinks.map((icon) => {
    return (
      <a href={icon.link} target="__blank">
        <i className={`socialicon ${icon.icon}`}></i>
      </a>
    );
  });

  return (
    <div className="footercontainer">
    <div className="footer flexrow">
      <div className="contentsection flexcol">
        <div className="logotext flex">
          <span className="border vertical"></span>
          <strong>mDreams Pictures</strong>
        </div>
      </div>
      <div className="linkssection flexcol">
        <Footertitle title="Links" />
        <div className="linksrow">
          {linksrow}
        </div>
      </div>
      <div className="office flexcol">
        <Footertitle title="Contact info" />
        <span>
          <a
            target="__blank"
            className="flexcol"
            href="https://www.google.com/maps/place/Concordia+University+FG+building/@45.4943412,-73.5806225,17z/data=!3m2!4b1!5s0x4cc91a6bc2f6938b:0x279170faf3218781!4m5!3m4!1s0x4cc91a6bea31d02b:0x5f2b41af9ca5c58b!8m2!3d45.4943412!4d-73.5784338"
          >
            <span>Sir George Williams Campus</span>
            <span>EV Building 3.260</span>
          </a>
        </span>
        <span>Montréal, QC</span>
        <span>Canada</span>
        <span>
          Téléphoner: <a href="tel:514-123-4567">(514) 123-4567</a>
        </span>
        <span>
          Email: <a href="mailto:email@email.com">email@email.com</a>
        </span>
      </div>
    </div>
    <div className="sociallinks">{sociallinksrow}</div>
    <div className="copyrightmsg">
       Copyright All Rights Reserved © {new Date().getFullYear()}
    </div>
    </div>
  );
};
export default Footer;
