import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { links } from '../../Arrays';
import ImgLoaded from '../Imgloaded/Imgloaded';
import './ScrollContainer.css';
import Button from '../Button/Button';
const ScrollContainer = props => {
    const {state, fullpageApi} = props;
    const location = useLocation()
    // useEffect(()=> {
    //     fullpageApi?.moveTo(location.hash)
    // }, [location, fullpageApi])
    return (
       <>
        <div className="section homebanner item">
            <h1 className="title mdreamstitle" id='home'>
                mDreams Pictures
            </h1>
        </div>
        <div className="section s1" id='technologies'>
            <div className="flexrow sectioninfo">
                <div className="card box">
                    <ImgLoaded img='https://mcusercontent.com/013dcbdd2fe47e8cd211361cd/images/1fc7238d-98ee-c3ae-627f-e5dfe4d8e62a.jpg' />
                </div>
                <div className="righttext">
                    <div className="texts">
                        <h1>AUGMENTED REALITY</h1>
                        <p>Augmented reality (AR) is a live direct or indirect view of a physical, real-world environment whose elements areaugmented (or supplemented) by computer-generated sensory input such as sound, video, graphics or GPS data. It is related to a more general concept called mediated reality, in which a view of reality is modified (possibly even diminished rather than augmented) by a computer. As a result, the technology functions by enhancing one’s current perception of reality. AR can be used in multiple ways, like medical, army, industry, mass media, city rebuilt, and entertainment. To enable rapid development of Augmented Reality Application, some software development kits (SDK) have emerged. A few SDK such as CloudRidAR  leverage cloud computing for performance improvement. Some of the well known AR SDKs are offered by Vuforia, Mobinett AR, Wikitude, Blippar and Layar.</p>
                    </div>
                    <div className="projectsiconsrow">
                        <div className="projicon">
                            <i className='fa fa-paint-brush'></i>
                            <span>Fun</span>
                            <p>A fun new technology</p>
                        </div>
                        <div className="projicon">
                            <i className='fa fa-user'></i>
                            <span>For everyone</span>
                            <p>Everyone can use it.</p>
                        </div>
                        <div className="projicon">
                            <i className='fa fa-globe'></i>
                            <span>Immersive</span>
                            <p>Explore a new world.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="section  superr" id='superr'>
            <h1>Super-R</h1>
            <div className="superrcont">
                <img src="https://mdreams-stage.cciff.ca/w/wp-content/uploads/2015/06/SUPER-R-ppt-cover-01.jpg" alt="" />
                <div className="text">
                    <p>Congratulations on mDream Inc.’s Super-R for winning 3rd place in the big data and next-gen information technology’s 4th China (Jinan) New Growth Drivers International High-Level Talents and Entrepreneurship Contest!</p>
                    <p>For detailed information on the contest, visit:</p>
                    <a href="http://competition.jn-rencai.com/">http://competition.jn-rencai.com/</a>
                    <p>Super R is a new algorithm to upscale standard resolution video to super resolution in real-time and is based on overall technological improvements in 5G, 8k and AI. It is flexible; it can take low-resolution images or video datasets and train the system to output HD, 2K, 4K, or 8K images or videos. It also is not dependent on any hardware; it can run on servers or local computers without requiring the specified AI-enabled GPU. The possible applications of the product range in many fields, such as healthcare, surveillance, and remote education.</p>
                    <p>Besides the 4th China New Growth Drivers International High-Level Talents and Entrepreneurship Contest, Super-R won the first seed funding round from the Canadian government agency, Mitacs.</p>
                    <p>Currently, Super-R’s developers want to collaborate with content providers, show producers, video on demand platforms and visual effects studios to take the technology to a whole new level. 5G implementation, mobile use, and remote working are all growing rapidly worldwide. Super-R is positioned to take advantage of these socioeconomic trends.</p>
                </div>
            </div>
        </div>
        <div className="section  space" id='illimitable-space-system'>
            <h1>Illimitable space system</h1>
            <div className="superrcont">
                <iframe src="https://player.vimeo.com/video/84041123" frameborder="0"></iframe>
                <div className="text">
                   <p>Illimitable Space System(ISS) is invited by Dr. Miao Song in Concordia University in Canada, and her professional tech team improve the following version of ISS. The Illimitable Space System (ISS) as a configurable artist’s toolbox in terms of artistic performance (such as dance or theatre production), music visualization, and interactive documentary controlled with gestures and voice. ISS also has the functionalities of interactive projection mapping, voice recognition, realtime audio/video effects through gesture and motion controls in order to achieve the mixed reality and immersive visual effects.</p>
                   <p>We are working on flexible and configurable multi-modal interactive Illimitable Space System platform versions ISS, ISSv2, and ISSv3 that serve different platforms and computing environments where our technical and VFX teams develop design and develop modules and plugins for events, on-stage deployment, installations, and the like.</p>
                 </div>
            </div>
        </div>
        <div className="section s2" id='production-film'>
        <div className="flexrow sectioninfo rowreverse">
                <div className="card box">
                    <ImgLoaded img='https://images.unsplash.com/photo-1631477076114-9123f721b9dc?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80' />
                </div>
                <div className="righttext">
                    <div className="texts">
                        <h1>Game and film making</h1>
                        <p>mDreams Picture has professional team to work on game and film making, and they made remarkable progress in both industry. The director lead her team working on changing video game into a real movie. Meanwhile, Dr. Miao Song is awarding film producer in documentary.</p>
                    </div>
                    <div className="projectsiconsrow">
                       <div className="imgcard">
                           <img src="https://mdreams-stage.cciff.ca/w/wp-content/uploads/2015/07/poster.png" alt="" />
                       </div>
                       <div className="imgcard">
                           <img src="https://mdreams-stage.cciff.ca/w/wp-content/uploads/2015/06/2.jpg" alt="" />
                       </div>
                       <div className="imgcard">
                           <img src="https://mdreams-stage.cciff.ca/w/wp-content/uploads/2015/06/3.png" alt="" />
                       </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="section s3" id='about'>
           <div className="backgrouund">
           <h1>Background</h1>
            <p>With the rapid development of computer hardware and software, the art and culture become more important to society. Information technology is based on interactive digital media. It is consist of real-time computer animation, game production, and the applications development in education, military, medical field more widely. But it is a new area by using the real-time technology in the traditional arts. How to combine science and arts is a challenge and need to be solved. People use non-interactive computer animation as the background, light, music and other elements on stage performance. This tech has been used in some professional performance group, which also created the “fake” interaction effects with these techniques. Actors can have more performance and audience can have more imagination from the stage performance.</p>
            <p>The Chinese people are enthusiastic consumers of all forms of media and entertainment. Thus, China has the second largest film market in the world and newly emerging as one of the largest Internet consumer market — with about one half of its population online. China’s total media and entertainment revenue has grossed at approximately US$59.4 billion in 2010. In 2015, this value expects to increase to an estimated value of US$137.9 billion.</p>
           </div>
           <div className="intro">
               <h2>Introduction</h2>
               <p>mDreams Pictures. Inc, is a company specializing in the new mass media, new technology, and  film production. Its academic subdivision, mDreams Stage, makes the research-creation ISS systems to support interactive multimodal installations and productions with collaboration with District 3 and Concordia University. It stared off as a New HCI in theatre and interactive documentaries. ISS is a Kinect-based audio-visual framework for multimodal (gestures, voice, etc.) installations for performance arts, interactive documentary films, games, and the like. mDreams Pictures. Inc improve AR technology in order to use in mobile application and glasses. Company have a long term strategy in enlarge core team, improve technologies, and bring more social and financial values.</p>
           </div>
        </div>
        <div className="section s4" id='team'>
            <h1>Members</h1>
            <div className="membersrow">
                <div className="member">
                    <img src="https://cciff.ca/Content/2021/img/En/member_photos/miao.JPG" alt="" />
                    <div>
                    <h2>Dr. Miao Song – CEO & Co-founder</h2>
                    <p>Dr. Miao Song is the co-founder of mDreams Pictures. Inc. She also works in Concordia University as professor of Computer science and Art. She has full of experience in teaching animation, programming, interactive design, and virtual reality. By putting traditional art into the latest technology, Dr. Miao Song have cooperated with other universities: McGill University, Carnegie Cameroon University, University of Texas USA, Beijing Normal University, the Central Academy of Drama, Beijing film Academy and so on.</p>
                    </div>
                </div>
                <div className="member">
                    <img src="https://mcusercontent.com/013dcbdd2fe47e8cd211361cd/images/90dddabd-dcfb-f4a6-7b39-47b81b9b7930.jpg" alt="" />
                    <div>
                    <h2>Serguei Mokhov – CTO</h2>
                    <p>Serguei A. Mokhov is CTO and co-founder in mDreams Picture. Inc. He also works in Concordia University as Affiliate Assistant Professor, CSE and CIISE. He is working on follow-up research from doctoral studies, collaborating with other Faculty members on a new program (Diploma in Entertainment Technology) development and Co-applicant for Team Startup Grant with S.P. Mudur, M. Song, and J.-C. Bustros. Currently Dr. Serguei focus on computer graphics, interactive, digital media and digital theatrical stage performances, interactive documentaries, cinematic 3D action, network programming, virtual research and others.</p>
                    </div>
                </div>
            </div>
            <div className="bot">
                <div className="stats">
                <div id="revolvermap" align="center" className="wcustomhtml"> <div className="ab"> <div className="abc"> <iframe className="adc" scrolling="no" frameBorder="0" allowtransparency="true" width="200" height="200" src="//rf.revolvermaps.com/w/6/a/a2.php?i=5dv1qe7od2v&amp;m=7&amp;c=e63100&amp;cr1=ffffff&amp;f=arial&amp;l=0&amp;bv=90&amp;lx=-400&amp;ly=400&amp;hi=20&amp;he=7&amp;hc=a8ddff&amp;rs=80"></iframe> </div> </div> <script type="text/javascript" src="//rf.revolvermaps.com/0/0/8.js?i=5nv91eyl5fm&amp;m=0&amp;c=ff0000&amp;cr1=ffffff&amp;f=arial&amp;l=33" async="async"></script> </div>

                </div>
                <div className="footer">
                     All rights reserved mDreams Pictures. Inc.  {new Date().getFullYear()}
                </div>
            </div>
        </div>
       </>
    );
};

ScrollContainer.propTypes = {
    
};

export default ScrollContainer;