import React from 'react';
import PropTypes from 'prop-types';
import { TemplatePage } from './TemplatePage';
import NewsCard from '../../../Components/Cards/NewsCard';
import useGetArticles from '../../../Dashboard/Services/GetArticles';

const News = props => {
    const news = useGetArticles()
    // const news = [
    //     {
    //         title: 'News 1',
    //         description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Ab consectetur beatae eaque totam, non temporibus excepturi voluptatum quod ipsa ipsum ducimus itaque consequatur veritatis soluta! Totam error ut tempora ab?',
    //         img: 'https://images.unsplash.com/photo-1518791841217-8f162f1e1131?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60',
    //         date: '01/01/2020',
    //         link: '/news1',
    //         content: '',
    //         gallery: [],
    //     },
    //     {
    //         title: 'News 2',
    //         description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Ab consectetur beatae eaque totam, non temporibus excepturi voluptatum quod ipsa ipsum ducimus itaque consequatur veritatis soluta! Totam error ut tempora ab?',
    //         img: 'https://images.unsplash.com/photo-1518791841217-8f162f1e1131?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60',
    //         date: '01/01/2020',
    //         link: '/news2',
    //         content: '',
    //         gallery: [],
    //     },
    //     {
    //         title: 'News 3',
    //         description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Ab consectetur beatae eaque totam, non temporibus excepturi voluptatum quod ipsa ipsum ducimus itaque consequatur veritatis soluta! Totam error ut tempora ab?',
    //         img: 'https://images.unsplash.com/photo-1518791841217-8f162f1e1131?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60',
    //         date: '01/01/2020',
    //         link: '/news3',
    //         content: '',
    //         gallery: [],
    //     },
    //     {
    //         title: 'News 4',
    //         description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Ab consectetur beatae eaque totam, non temporibus excepturi voluptatum quod ipsa ipsum ducimus itaque consequatur veritatis soluta! Totam error ut tempora ab?',
    //         img: 'https://images.unsplash.com/photo-1518791841217-8f162f1e1131?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60',
    //         date: '01/01/2020',
    //         link: '/news4',
    //         content: '',
    //         gallery: [],
    //     },
    // ]
    const newsrow = news?.map((news, i) => {
        return (
            <NewsCard key={i} {...news} />
        )
    })

    return (
        <TemplatePage title='News' bannerTitle='Latest news' img='https://mcusercontent.com/013dcbdd2fe47e8cd211361cd/images/4ce1e8b3-b0c6-de70-3196-551df7b811f6.jpg'> 
            <div className="newsrow">
                {newsrow}
            </div>
        </TemplatePage>
    );
};

News.propTypes = {
    
};

export default News;