import React from "react";
import { useLocation } from "react-router-dom";
import { Link, Route, Switch } from "react-router-dom";
import ImgLoaded from "../../Components/Imgloaded/Imgloaded";
import { sendEmail } from "../../Functions";
import Footer from "../Footer/Footer";
import { Navbar } from "../Navbar/Navbar";
import "./Body.css";
import About from "./Pages/About";
import Article from "./Pages/Article";
import Contact from "./Pages/Contact";
import { Home } from "./Pages/Home";
import News from "./Pages/News";
import Projects from "./Pages/Projects";
import Publications from "./Pages/Publications";

const Body = () => {

  const location = useLocation()
  const articleId =  location.pathname.split("/")[3]
  console.log(location.pathname)
  const isHome = location.pathname ===  '/' ? true : false 
  return (
  <>
  <Navbar isHome={isHome}/>
    <div className="body">
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path={'/projects'} component={Projects}/>
        <Route exact path={'/news'} component={News} />
        <Route exact path={'/publications'} component={Publications} />
        <Route exact path={'/about'} component={About} />
        <Route exact path={'/contact'} component={Contact} />
        {/* <Route exact path={`/news/article/${articleId}`}>
          <Article id={articleId} />
        </Route> */}
        <Route>
          <div className="body__404">
            <div className="404content">
              <h1>404</h1>
              <p>Page not found</p>
              <Link onClick={()=> sendEmail()}>
                <button className="appbtn">Go to Home</button>
              </Link>
            </div>
            <ImgLoaded img='https://mcusercontent.com/013dcbdd2fe47e8cd211361cd/images/c0698712-100c-3f16-357a-dc4c566e06eb.jpg' />
          </div>
        </Route>
      </Switch>
    </div>
    {!isHome && <Footer />}
    </>
  );
};
export default Body;
