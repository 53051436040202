import ReactFullpage from '@fullpage/react-fullpage'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useLocation } from 'react-router-dom'
import { links } from '../../../Arrays'
import Button from '../../../Components/Button/Button'
import ImgLoaded from '../../../Components/Imgloaded/Imgloaded'
import ScrollContainer from '../../../Components/ScrollContainer/ScrollContainer'


export const Home = (props) => {
  const {} = props
  const location = useLocation()
  const length = 3
  const [animate, setAnimate] = useState({
    index: 0,
    opacity: 1
  })
  const handleOpacity = (scrollPos) => {
    setAnimate({
      index: Math.round(scrollPos),
      opacity: scrollPos
    })
  }

  return (
    <div className={'homepage webpage'} onScroll={(e)=> {handleOpacity((e.target.scrollTop * length)/(e.target.scrollHeight))}}>
      <Helmet>
        <title>mDreams Pictures</title>
      </Helmet>
      {/* <ReactFullpage
        licenseKey={"KEGX6-QRCM9-4KJMI-J08NH-KOSVK"}
        scrollingSpeed={1}
        navigation={true}
        navigationTooltips={links.map(({component})=> component)}
        anchors={links.map(({anchor})=> anchor)}
        render={({ state, fullpageApi }) => {
          return (
            <ReactFullpage.Wrapper> */}
              <ScrollContainer  />
            {/* </ReactFullpage.Wrapper>
          );
        }}
      /> */}
      
      {/* <div className="homebanner item">
          <ImgLoaded img='https://mcusercontent.com/013dcbdd2fe47e8cd211361cd/images/c0f18e7b-ce8b-945f-4c5e-e99fb95fb8c6.jpg'/>
      </div>
      <div className="item1 item">
          <ImgLoaded img='https://mcusercontent.com/013dcbdd2fe47e8cd211361cd/images/efb9c1df-cad0-c312-2076-5d8827aa7669.jpg' />
          <div className="righttext">
             <div className="textcont">
                  <h1>Lorem Title</h1>
                  <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Maxime quibusdam officiis odio nostrum recusandae. Magni aliquam sed laudantium aut commodi mollitia. Sequi impedit, ea harum placeat, cupiditate debitis est libero laborum fuga voluptates quas maxime eligendi eveniet! Eligendi, odit ab?</p>
             </div>
             <div className="btncont flexrow">
                 <Button text='Learn More' />
                 <Button text='Learn More' />
             </div>
          </div>
      </div>
      <div className="item2 item"></div> */}
      {/* <ReactFullpage 
        licenseKey={'KEGX6-QRCM9-4KJMI-J08NH-KOSVK'}
        scrollingSpeed={1000}
        anchors={['home', 'projects', 'news', 'publications', 'contact']}
        render={({ state, fullpageApi }) => {
          return (
            <ReactFullpage.Wrapper>
              <div className="section" id='home' style={{marginTop: 80}}>
                <p>Section 1 (welcome to fullpage.js)</p>
                <button onClick={() => fullpageApi.moveSectionDown()}>
                  Click me to move down
                </button>
              </div>
              <div className="section" id='projects'>
                <p>Section 2</p>
              </div>
            </ReactFullpage.Wrapper>
          );
        }}
      /> */}
    </div>
  )
}