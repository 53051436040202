import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { TemplatePage } from './TemplatePage';
import Title from '../../../Components/Title/Title';
import Map from '../../../Components/Map/Map';
import Input from '../../../Components/Input/Input';
import Button from '../../../Components/Button/Button';
import ContactForm from '../../../Components/ContactForm/ContactForm';

const Contact = props => {


    return (
        <TemplatePage title='Contact' bannerTitle='Contact' img='https://mcusercontent.com/013dcbdd2fe47e8cd211361cd/images/e913b308-4e70-d766-39eb-3dd42e2a082e.jpg'>
           <div className="contactpage">
                <Title title='Our Location' />
                <Map center={[45.4943412, -73.5806225]} lat={45.4943412} lng={-73.5806225} />
                <Title title={'Contact Us'}/>
                <ContactForm />
                <Title title={'About us Overview'}/>
                <div className="boxes">
                    <div className="box">
                        <i className='fal fa-at'></i>
                        <strong>Email Adress</strong>
                        <a href='mailto:info@mail.com' className="graytext">info@mail.com</a>
                    </div>
                    <div className="box">
                        <i className='fal fa-location'></i>
                        <strong>Our Location</strong>
                        <a className="graytext" href='https://www.google.com/maps/place/Concordia+SGW+Campus,+Montreal,+QC/data=!4m2!3m1!1s0x4cc91a6a64ea3d09:0x1649d951ed37f998?sa=X&ved=2ahUKEwiU0KrIgr_3AhWKHc0KHWGABsYQ8gF6BAhpEAE'>Sir George Williams Campus, H3G 1M8</a>
                    </div>
                    <div className="box">
                        <i className='fab fa-facebook-f'></i>
                        <strong>Facebook</strong>
                        <a href='https://www.facebook.com/mdreamsstage/info?tab=page_info' className="graytext">mDreams Stage</a>
                    </div>
                </div>
            </div>
        </TemplatePage>
    );
};

Contact.propTypes = {
    
};

export default Contact;