export const links = [
  {
    text: 'home',
    anchor: '#home',
    component: 'Home',
  },
  {
    text: 'technologies',
    anchor: '#technologies',
    component: 'Technologies',
  },
  {
    text: 'Super-R',
    anchor: '#superr',
    component: 'SuperR',
  },
  {
    text: 'Space system',
    anchor: '#illimitable-space-system',
    component: 'IllimitableSpaceSystem',
  },
  {
    text: 'Production & film',
    anchor: '#production-film',
    component:  'Production & film',
  },
  {
    text: 'About',
    anchor: '#about',
    component: 'About',
  },
  {
    text: 'Team',
    anchor: '#team',
    component: 'Team',
  }
]