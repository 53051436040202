import React from 'react'
import { Link } from 'react-router-dom'
import ImgLoaded from '../Imgloaded/Imgloaded'
import './Logo.css'

export const Logo = ({img='https://mcusercontent.com/013dcbdd2fe47e8cd211361cd/images/eea81702-84b3-ca0e-13de-69763f3fb2a0.png'}) => {
  
  return (
    <Link to='/' className="logo" href='https://cciff.ca/'>
      <ImgLoaded img={'https://mcusercontent.com/013dcbdd2fe47e8cd211361cd/images/b045142d-f2f9-bb41-9658-8b07c871b789.png'} />
    </Link>
  )
}